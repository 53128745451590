import { Component, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ZipCodeChangeBaseComponent } from '../zip-code-change-base.component';
import { LoginService } from '@app/login/services';
@Component({
  selector: 'app-zip-code-change-desktop',
  templateUrl: './zip-code-change-desktop.component.html',
  styleUrls: ['./zip-code-change-desktop.component.scss'],
})
export class ZipCodeChangeDesktopComponent extends ZipCodeChangeBaseComponent {
  @Input() zipCode : number;

  constructor(
    private popoverController: PopoverController,
    platformService: PlatformService,
    loginService: LoginService,
  ) {
    super(platformService,loginService);
  }

  async switchGuestZip(event: any) {
    await this.popoverController.dismiss();
    await this.loginService.switchGuestZip(Number(this.zipCode));
  }
}

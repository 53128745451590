import { Component, Input, ViewChild } from '@angular/core';
import { LoginService } from '@app/login/services/login.service';
import { IonSearchbar, ModalController, PopoverController } from '@ionic/angular';
import { PlatformEnum, PlatformService } from '@panamax/app-state';

@Component({
  template: '',
})
export class ZipCodeChangeBaseComponent {
  @Input() showSearchbar = true;
  @ViewChild(IonSearchbar, { static: false }) searchbar: IonSearchbar;
  zipCodeLength = 5;

  searchText = '';
  PlatformEnum = PlatformEnum;

  constructor(
    readonly platformService: PlatformService,
    readonly loginService: LoginService,
  ) {}

  validateZipCode(event: any) {
    const input = event.target as HTMLInputElement;

    if (input.value.length > this.zipCodeLength) {
      input.value = input.value.substring(0, this.zipCodeLength);
    }
  }

  numericOnly(event: any): boolean {
    let pattern = /^([0-9])$/;
    let result = pattern.test(event.key);
    return result;
 }
}

import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UserActions } from '@app/user/store/actions/action-types';
import { Capacitor } from '@capacitor/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  PlatformEnum,
  PlatformService,
  UsabillaService,
} from '@panamax/app-state';
import { User } from '@usf/user-types/user';
import { combineLatest, Subscription } from 'rxjs';
import {
  getActiveCustomersByUserId,
  getActiveCustomersByUserIdSuccess,
} from '@app/ngrx-customer/store/actions';
import { getCustomers, selectedCustomer } from '@app/ngrx-customer/store';
import { Customer } from '@usf/customer-types';

@Component({
  selector: 'app-invite-existing-user-modal',
  templateUrl: './invite-existing-user-modal.component.html',
  styleUrls: ['./invite-existing-user-modal.component.scss'],
})
export class InviteExistingUserModalComponent
  implements OnInit, OnDestroy, ViewWillEnter
{
  @Output() selectExistingUser = new EventEmitter<any>();
  @Output() dismissEvent: EventEmitter<void> = new EventEmitter();
  @Output() mobileUserInfoSelected = new EventEmitter<boolean>();
  @Output() mobileCustomersSelected = new EventEmitter<boolean>();

  SECTIONS = ['userInformation', 'customers'];
  MAX_CUSTOMERS = 100;

  searchKey = '';
  selectedOption = '';

  customers = [];
  searchedCustomers = [];
  selectedCustomer: Customer;

  result: {
    user: User;
    customers: string[];
  } = { user: null, customers: [] };

  userSearch$: Subscription = new Subscription();
  selectedUserCustomers$: Subscription = new Subscription();
  customers$: Subscription = new Subscription();

  isUserSelected = false;
  isLoading = false;
  customersMaxLimitReached = false;

  constructor(
    readonly modalController: ModalController,
    public platform: PlatformService,
    public translateService: TranslateService,
    public store: Store,
    private usabillaService: UsabillaService,
    private actionSubject: ActionsSubject,
  ) {}

  ngOnInit() {
    if (!Capacitor.isNativePlatform()) {
      this.usabillaService.hide();
    }

    this.selectedOption =
      this.platform.platformType === PlatformEnum.mobile
        ? ''
        : 'userInformation';

    this.userSearch$ = this.actionSubject
      .pipe(ofType(UserActions.searchUserByUsernameSuccess))
      .subscribe(data => {
        this.result.user = data['user'];
        if (data['user']?.userName == '') {
          this.clearUser();
        }
        this.isLoading = false;
      });

    this.customers$ = combineLatest([
      this.store.select(getCustomers),
      this.store.select(selectedCustomer),
    ]).subscribe(([customers, selectedCustomer]) => {
      this.selectedCustomer = selectedCustomer;
      const selectedCustomerNumber =
        selectedCustomer?.customerNumber?.toString();
      this.result.customers = selectedCustomerNumber
        ? [selectedCustomerNumber]
        : [];
      this.customers = customers.map(customer => {
        const customerNumberStr = customer.customerNumber.toString();
        const isSelected = this.result.customers.includes(customerNumberStr);
        return {
          ...customer,
          selected: customerNumberStr === selectedCustomerNumber || isSelected,
        };
      });

      this.searchedCustomers = this.customers;
    });

    this.selectedUserCustomers$ = this.actionSubject
      .pipe(ofType(getActiveCustomersByUserIdSuccess))
      .subscribe(data => {
        const userCustomerIds = data?.userCustomer?.customerIds || [];
        const selectedCustomerNumbers = userCustomerIds
          .filter((customer: any) => customer.selected)
          .map((customer: any) => customer.customerNumber.toString());

        this.customers = this.customers.map(customer => {
          const customerNumberStr = customer.customerNumber.toString();
          const isSelected =
            selectedCustomerNumbers.includes(customerNumberStr) ||
            customerNumberStr ===
              this.selectedCustomer?.customerNumber?.toString();
          const isDisabled =
            selectedCustomerNumbers.includes(customerNumberStr);

          return {
            ...customer,
            selected: isSelected,
            disabled: isDisabled,
          };
        });
        this.searchedCustomers = this.customers;
        const selectedCustomerNumberStr =
          this.selectedCustomer?.customerNumber?.toString();

        this.result.customers = this.result.customers.filter(customerNumber =>
          this.customers.some(
            customer =>
              customer.customerNumber.toString() === customerNumber &&
              !customer.disabled,
          ),
        );
        if (
          selectedCustomerNumberStr &&
          !this.result.customers.includes(selectedCustomerNumberStr) &&
          !this.customers.find(
            c => c.customerNumber.toString() === selectedCustomerNumberStr,
          )?.disabled
        ) {
          this.result.customers.push(selectedCustomerNumberStr);
          this.selectExistingUser.emit({
            user: this.result.user,
            customers: [this.selectedCustomer],
          });
        }

        this.isLoading = false;

        if (this.searchedCustomers.length === 0) {
          this.clearUser();
        }
      });
  }

  handleInput(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^a-zA-Z0-9-_]/g, '');
    this.searchKey = input.value;
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.searchCustomer(event);
    }
  }

  searchCustomer(event: any) {
    this.searchKey = event?.target?.value || '';
    if (this.searchKey !== '') {
      this.searchedCustomers = this.customers
        .filter(
          c =>
            !this.searchKey ||
            c.customerName
              .toLowerCase()
              .includes(this.searchKey.toLowerCase()) ||
            c.customerNumber.toString().includes(this.searchKey),
        )
        .map(customer => {
          const isSelected = this.result.customers.includes(
            customer.customerNumber.toString(),
          );
          return {
            ...customer,
            selected: isSelected,
          };
        });
    } else {
      this.searchedCustomers = this.customers;
    }
  }

  isCustomerChecked(customer: any) {
    return (
      this.result?.customers?.indexOf(customer?.customerNumber?.toString()) > -1
    );
  }

  toggleCustomerSelection(customer: any, event: Event): void {
    event.stopPropagation();
    customer.selected = !customer.selected;
    this.updateCustomersSelected(customer.customerNumber);
  }

  updateCustomersSelected(customerNumber: number) {
    const customerNumberStr = customerNumber.toString();
    const customer = this.searchedCustomers.find(
      cust => cust.customerNumber.toString() === customerNumberStr,
    );
    if (!customer) {
      return;
    }
    if (this.result.customers.includes(customerNumberStr)) {
      this.result.customers = this.result.customers.filter(
        num => num !== customerNumberStr,
      );
      customer.selected = false;
    } else {
      if (this.result.customers.length >= this.MAX_CUSTOMERS) {
        this.customersMaxLimitReached = true;
        return;
      }
      this.result.customers.push(customerNumberStr);
      customer.selected = true;
    }
    this.customersMaxLimitReached =
      this.result.customers.length >= this.MAX_CUSTOMERS;
    this.customers = this.customers.map(cust => {
      if (cust.customerNumber.toString() === customerNumberStr) {
        return { ...cust, selected: customer.selected };
      }
      return cust;
    });
    this.selectExistingUser.emit({
      user: this.isUserSelected ? this.result.user : null,
      customers: this.result.customers,
    });
  }

  getSingleCustomerSelectedName() {
    const selectedCustomerNumber = Array.from(this.result.customers)[0];
    return this.customers.find(
      c => c.customerNumber.toString() === selectedCustomerNumber,
    )?.customerName;
  }

  getSelectedCustomersCount() {
    return (
      this.translateService.instant('i18n.notifications.selected') +
      ': ' +
      this.result.customers.length
    );
  }

  setSelectedOption(option: string) {
    if (
      option === 'userInformation' &&
      this.platform.platformType === PlatformEnum.mobile
    ) {
      this.mobileUserInfoSelected.emit(true);
    } else if (
      option === 'customers' &&
      this.platform.platformType === PlatformEnum.mobile
    ) {
      this.mobileCustomersSelected.emit(true);
    }
    this.selectedOption = option;
  }

  searchUser(event: any) {
    const value = event?.target?.value?.trim() || '';
    if (!this.isLoading && value !== '') {
      this.isLoading = true;
      this.isUserSelected = false;
      this.store.dispatch(
        UserActions.searchUserByUsername({ userName: value }),
      );
    }
  }

  selectUser() {
    if (this.isUserSelected) {
      this.isUserSelected = false;
      this.selectExistingUser.emit({ user: null, customers: [] });
      this.resetCustomerSearch();
    } else {
      this.isUserSelected = true;
      this.result.customers = [];
      this.selectExistingUser.emit({
        user: this.result.user,
        customers: [],
      });
      this.store.dispatch(
        getActiveCustomersByUserId({ userId: this.result?.user?.userId }),
      );
    }
  }

  clearUser() {
    this.result = { user: null, customers: [] };
    this.selectExistingUser.emit(this.result);
    this.resetCustomerSearch();
  }

  resetSearch() {
    this.searchKey = '';
  }

  resetCustomerSearch() {
    const selectedCustomerNumber =
      this.selectedCustomer?.customerNumber?.toString();
    this.customers = this.customers.map(customer => {
      const customerNumberStr = customer.customerNumber.toString();
      const isSelected = customerNumberStr === selectedCustomerNumber;
      return {
        ...customer,
        selected: isSelected ? isSelected : false,
        disabled: false,
      };
    });

    this.searchedCustomers = [...this.customers];

    this.result.customers = selectedCustomerNumber
      ? [selectedCustomerNumber]
      : [];

    this.customersMaxLimitReached = false;
  }

  clearSelectedOption() {
    this.selectedOption = '';
  }

  mobileUserInfoSelectedNext() {
    this.setSelectedOption('customers');
  }

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    this.userSearch$.unsubscribe();
    this.customers$.unsubscribe();
    this.selectedUserCustomers$.unsubscribe();
  }

  ionViewWillEnter(): void {
    this.resetSearch();
  }
}

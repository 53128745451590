import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { RouterModule } from '@angular/router';
import { IonNav, IonicModule } from '@ionic/angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ErrorDialogueComponent } from './components/error-dialogue/error-dialogue.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderModule } from './components/header/header.module';
import { ModalWithNavComponent } from './components/modal-with-nav/modal-with-nav.component';
import { MessageComponent } from './components/popovers/message/message.component';
import { QuantityInputComponent } from './components/quantity-input/quantity-input.component';
import { SearchResultNotFoundComponent } from './components/search-result-not-found/search-result-not-found.component';
import { UsfChipComponent } from './components/usf-chip/usf-chip.component';
import { UsfProductTagListComponent } from './components/usf-product-tag-list/usf-product-tag-list.component';
import { UsfTableComponent } from './components/usf-table/usf-table.component';
import { NumberDirective } from './directives/number.directive';
import { RegexInputValidatorDirective } from './directives/regex-input-validator.directive';
import { NavigationHelperService } from './helpers/navigation.helpers.service';
import { HttpHeaderInterceptor } from './interceptor/http-header.interceptor';
import { FormatCurrencyAndDecimalPipe } from './pipes/format-currency-and-decimal.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { TruncateWithEllipsisPipe } from './pipes/truncate-with-ellipsis.pipe';
import { ZipcodePipe } from './pipes/zipcode.pipe';
import { PowerReviewService } from './services/power-review.service';
import { ScreenSizeService } from './services/screen-size/screen-size.service';
import { ServiceHandlerService } from './services/service-handler.service';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { ListSkeletonComponent } from '@app/lists/components/list-skeleton/list-skeleton.component';
import { InventorySectionDesktopComponent } from '@inventory/components/inventory-section/desktop/inventory-section-desktop.component';
import { InventorySectionMobileComponent } from '@inventory/components/inventory-section/mobile/inventory-section-mobile.component';
import { InventorySectionTabletComponent } from '@inventory/components/inventory-section/tablet/inventory-section-tablet.component';
import { HttpTraceInterceptor } from '@panamax/app-state';
import { PowerCustomReviewRatingStarsComponent } from '@shared/components/power-review-rating-stars/power-custom-review-rating-stars.component';
import { PriceDisplayComponent } from '@shared/components/price-display/price-display.component';
import { ReplaceProductComponent } from '../lists/shared/replace-product/replace-product.component';
import { ProductDetailViewerComponent } from '../product-detail/components/product-detail-viewer/product-detail-viewer.component';
import { ProductInventoryInfoComponent } from '../product-detail/components/product-inventory-info/product-inventory-info.component';
import { AddToInventoryDesktopTabletComponent } from './components/add-to-inventory/add-to-inventory-desktop-tablet/add-to-inventory-desktop-tablet.component';
import { AddToInventoryInputComponent } from './components/add-to-inventory/add-to-inventory-mobile/add-to-inventory-input/add-to-inventory-input.component';
import { AddToInventoryMobileComponent } from './components/add-to-inventory/add-to-inventory-mobile/add-to-inventory-mobile.component';
import { AddToListDesktopTabletComponent } from './components/add-to-list/add-to-list-desktop-tablet/add-to-list-desktop-tablet.component';
import { AddToListInputComponent } from './components/add-to-list/add-to-list-mobile/add-to-list-input/add-to-list-input.component';
import { AddToListMobileComponent } from './components/add-to-list/add-to-list-mobile/add-to-list-mobile.component';
import { AddToOrderModalDesktopTabletComponent } from './components/add-to-order-modal/add-to-order-modal-desktop-tablet/add-to-order-modal-desktop-tablet.component';
import { AddToOrderModalMobileComponent } from './components/add-to-order-modal/add-to-order-modal-mobile/add-to-order-modal-mobile.component';
import { ExistingOrderMobileComponent } from './components/add-to-order-modal/add-to-order-modal-mobile/existing-order-mobile/existing-order-mobile.component';
import { StartNewOrderMobileComponent } from './components/add-to-order-modal/add-to-order-modal-mobile/start-new-order-mobile/start-new-order-mobile.component';
import { AddToOrderProductsComponent } from './components/add-to-order-modal/add-to-order-products/add-to-order-products.component';
import { CalendarV2ModalComponent } from './components/calendar-v2-modal/calendar-v2-modal.component';
import { CalendarV2SingleSelectComponent } from './components/calendar-v2-single-select/calendar-v2-single-select.component';
import { CustomScrollingModule } from './components/custom-virtual-scroll/virtual-scroll.module';
import { DecimalQuantityInputComponent } from './components/decimal-quantity-input/decimal-quantity-input.component';
import { DeleteGroupModalComponent } from './components/delete-group-modal/delete-group-modal.component';
import { DownloadOrderModalComponent } from './components/download-order-modal/download-order-modal.component';
import { HandPricingCPRandNGPComponent } from './components/hand-pricing-cpr-ngp-modal/hand-pricing-cpr-ngp-modal.component';
import { HandPricingPopoverComponent } from './components/hand-pricing-popover/hand-pricing-popover.component';
import { HelpModalDesktopComponent } from './components/help-modal/help-modal-desktop/help-modal-desktop.component';
import { HelpModalTouchComponent } from './components/help-modal/help-modal-touch/help-modal-touch.component';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { InlineToastComponent } from './components/inline-toast/inline-toast.component';
import { OrderInfoSidebarComponent } from './components/order-info-sidebar/order-info-sidebar.component';
import { PageTitleBannerComponent } from './components/page-title-banner/page-title-banner.component';
import { AccessDeniedComponent } from './components/popovers/access-denied/access-denied.component';
import { CatchWeightPopoverComponent } from './components/popovers/catch-weight-popover/catch-weight-popover.component';
import { EllipsisOptionsPopoverComponent } from './components/popovers/ellipsis-options/ellipsis-options-popover.component';
import { LoadingSpinnerComponent } from './components/popovers/loading-spinner/loading-spinner.component';
import { OrderSyncingComponent } from './components/popovers/order-syncing/order-syncing.component';
import { PromoteAppComponent } from './components/popovers/promote-app/promote-app.component';
import { RecentPurchasePopoverComponent } from './components/popovers/recent-purchase/recent-purchase-popover.component';
import { WarningPopoverWithTitleComponent } from './components/popovers/warning-popover/warning-popover-with-title/warning-popover-with-title.component';
import { WarningPopoverComponent } from './components/popovers/warning-popover/warning-popover.component';
import { ProductCardEllipsisComponent } from './components/product-card-ellipsis/product-card-ellipsis.component';
import { ProductCardImageComponent } from './components/product-card-image/product-card-image.component';
import { ProductCardInformationComponent } from './components/product-card-information/product-card-information.component';
import { ProductCardItemNoteComponent } from './components/product-card-item-note/product-card-item-note.component';
import { ProductCardLineNumberComponent } from './components/product-card-line-number/product-card-line-number.component';
import { ProductCardOrderingComponent } from './components/product-card-ordering/product-card-ordering.component';
import { ProductCardPromotionsBannerComponent } from './components/product-card-promotions-banner/product-card-promotions-banner.component';
import { ProductDetailCABCardComponent } from './components/product-detail-cab-card/product-detail-cab-card.component';
import { ProductShareButtonComponent } from './components/product-share-button/product-share-button.component';
import { ShowHideInputComponent } from './components/show-hide-input/show-hide-input.component';
import { SimpleActionModalComponent } from './components/simple-action-modal/simple-action-modal.component';
import { SimpleSpinnerModalComponent } from './components/simple-spinner-modal/simple-spinner-modal.component';
import { SortableSectionCaretsComponent } from './components/sortable-section-carets/sortable-section-carets.component';
import { SortableSectionDesktopTabletComponent } from './components/sortable-section/desktop-tablet/sortable-section-desktop-tablet.component';
import { SortableSectionMobileComponent } from './components/sortable-section/mobile/sortable-section-mobile.component';
import { UsfBetterBuyCardDesktopComponent } from './components/usf-better-buy-card/desktop/usf-better-buy-card-desktop.component';
import { UsfBetterBuyCardTouchComponent } from './components/usf-better-buy-card/touch/usf-better-buy-card-touch.component';
import { UsfBetterBuyDeclineModalComponent } from './components/usf-better-buy-decline-modal/usf-better-buy-decline-modal.component';
import { UsfCheckboxComponent } from './components/usf-checkbox/usf-checkbox.component';
import { UsfExpandablePanelComponent } from './components/usf-expandable-panel/usf-expandable-panel.component';
import { UsfFilteringBannerDesktopComponent } from './components/usf-filtering-banner/desktop/usf-filtering-banner-desktop.component';
import { UsfFilteringBannerMobileComponent } from './components/usf-filtering-banner/mobile/usf-filtering-banner-mobile.component';
import { UsfFilteringBannerTabletComponent } from './components/usf-filtering-banner/tablet/usf-filtering-banner-tablet.component';
import { UsfFilteringBannerComponent } from './components/usf-filtering-banner/usf-filtering-banner.component';
import { UsfFilterHostDirective } from './components/usf-filtering-modal/filter-host-directive/filter-host.directive';
import { UsfFilteringDatesOptionComponent } from './components/usf-filtering-modal/usf-filtering-options/usf-filtering-dates-option/usf-filtering-dates-option.component';
import { UsfFilterListOptionsComponent } from './components/usf-filtering-modal/usf-filtering-options/usf-filtering-list-options/usf-filtering-list-options.component';
import { UsfFilterTextEntryOptionsComponent } from './components/usf-filtering-modal/usf-filtering-options/usf-filtering-text-entry-options/usf-filter-text-entry-options.component';
import { UsfSortOptionsComponent } from './components/usf-filtering-modal/usf-filtering-options/usf-sort-options/usf-sort-options.component';
import { UsfProductCardInfoTouchComponent } from './components/usf-product-card-info/touch/usf-product-card-info-touch.component';
import { UsfProductCardNonSplitComponent } from './components/usf-product-card-non-split/usf-product-card-non-split.component';
import { UsfProductCardSplitAlternativeComponent } from './components/usf-product-card-split-alternative/usf-product-card-split-alternative.component';
import { UsfProductCardSplitOriginalComponent } from './components/usf-product-card-split-original/usf-product-card-split-original.component';
import { UsfProductCardDesktopComponent } from './components/usf-product-card/desktop/usf-product-card-desktop.component';
import { UsfProductCardTouchComponent } from './components/usf-product-card/touch/usf-product-card-touch.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { WillCallConfirmModalComponent } from './components/will-call-confirm-modal/will-call-confirm-modal.component';
import { WillCallModalComponent } from './components/will-call-modal/will-call-modal.component';
import { AlphaNumericAndroidDirective } from './directives/alpha-numeric-android.directive';
import { AlphaNumericDirective } from './directives/alpha-numeric.directive';
import { CustomDecimalDirective } from './directives/custom-decimal.directive';
import { DecimalDirective } from './directives/decimal.directive';
import { CurrencyDirective } from './directives/currency.directive';
import { LMADragDropModule } from './directives/drag-drop.module';
import { MaxLengthAndroidDirective } from './directives/max-length-android.directive';
import { PhoneDirective } from './directives/phone.directive';
import { ShowFeatureModule } from './directives/show-feature.module';
import { TestIdDirective } from './directives/test-id.directive';
import { TrimSpaceDirective } from './directives/trim-space.directive';
import { AcronymTitleCasePipe } from './pipes/acronym-title-case.pipe';
import { PhoneNumberFormatPipe } from './pipes/phone-number-format.pipe';
import { ReplaceStringPipe } from './pipes/replaceString.pipe';
import { ShowFeaturePipe, ShowSplitPipe } from './pipes/show-feature.pipe';
import { TimezoneDatePipe } from './pipes/timezone-date.pipe';
import { TruncateAtStartEllipsisPipe } from './pipes/truncate-at-start-ellipsis.pipe';
import { ProductDetailCardComponent } from './components/product-detail-card/product-detail-card.component';
import { ProductDetailAddToOrderModalComponent } from './components/product-detail-add-to-order-modal/product-detail-add-to-order-modal.component';
import { DeleteItemsConfirmationModalComponent } from './components/delete-items-confirmation-modal/delete-items-confirmation-modal.component';
import { ZipcodeDirective } from './directives/zipcode.directive';
import { SelectableItemComponent } from './components/selectable-item/selectable-item.component';
import { RadioBtnListComponent } from './components/radio-btn-list/radio-btn-list.component';
import { ImportOrderModalMobileComponent } from './components/import-order/import-order-modal-mobile/import-order-modal-mobile.component';
import { SharePrintDownloadComponent } from './components/share-print-download/share-print-download.component';
import { NumericAltDirective } from './directives/numeric-alt.directive';
import { ForceAppUpdateComponent } from './components/popovers/force-app-update/force-app-update.component';
import { CalendarV2Component } from './components/calendar-v2/calendar-v2.component';
import { DownloadImportOrderModalComponent } from './components/import-order/download-import-order-modal/download-import-order-modal.component';
import { ProductCardDrawerComponent } from './components/product-card-drawer/product-card-drawer.component';
import { LoadingSpinnerWMessageModalComponent } from './components/loading-spinner-w-message-modal/loading-spinner-w-message-modal.component';
import { HighlightedSelectableItemComponent } from './components/highlighted-selectable-item/highlighted-selectable-item.component';
import { BetterBuySavingsComponent } from './components/better-buy-savings/better-buy-savings.component';
import { WorksWellWithContainerComponent } from './components/works-well-with/container/works-well-with-container.component';
import { ModifiedAlphaNumericDirective } from './directives/modified-alpha-numeric.directive';
import { WorksWellWithModalComponent } from './components/works-well-with/modal/works-well-with-modal';
import { ProductShippingPriceComponent } from './components/product-shipping-price/product-shipping-price.component';
import { UsfSortModalComponent } from './components/usf-sort-modal/usf-sort-modal.component';
import { InviteNewUserModalComponent } from './components/invite-new-user-modal/invite-new-user-modal.component';
import { InviteNewUserService } from './services/invite-new-user/invite-new-user.service';
import { ObserveIntersectionDirective } from './directives/observe-intersection.directive';
import { DualPaneModalComponent } from './components/dual-pane/dual-pane-modal/dual-pane-modal.component';
import { BasicListComponent } from './components/dual-pane/left-side/basic-list/basic-list.component';
import { SingleSelectRadioComponent } from './components/dual-pane/right-side/single-select-radio/single-select-radio.component';
import { ModalWithNavInlineComponent } from './components/modal-with-nav-inline/modal-with-nav-inline.component';
import { ShowcaseDualPaneModalContainerComponent } from './components/modal-with-nav-inline/dual-pane-container/showcase-dual-pane-modal-container/showcase-dual-pane-modal-container.component';
import { SingleSelectCountableItemComponent } from './components/dual-pane/right-side/single-select-countable-item/single-select-countable-item.component';
import { SelectableInventoryComponent } from '../inventory/components/selectable-inventory/selectable-inventory.component';
import { PickAFileComponent } from './components/dual-pane/right-side/pick-a-file/pick-a-file.component';
import { MultiSelectCheckboxComponent } from './components/dual-pane/right-side/multi-select-checkbox/multi-select-checkbox.component';
import { FormatDateWithZeroOffsetPipe } from './pipes/format-date-with-zero-offset.pipe';
import { SingleSelectProductComponent } from './components/dual-pane/right-side/single-select-product/single-select-product.component';
import { SelectableProductComponent } from '../product-detail/components/selectable-product/selectable-product.component';
import { SelectableCountableItemComponent } from './components/selectable-countable-item/selectable-countable-item.component';
import { MassDeleteDualPaneContainerComponent } from '@app/lists/shared/delete-list/mass-delete-dual-pane-container/mass-delete-dual-pane-container.component';
import { SimpleDownloadOrdersModalComponent } from './components/download-order-modal/download-multiple-orders-modal/simple-download-orders-modal.component';
import { SearchBarMultiLineComponent } from './components/search-bar-multi-line/search-bar-multi-line.component';
import { MerchandisingProductCarouselComponent } from './components/merchandising-product-carousel/merchandising-product-carousel.component';
import { MultiInputAndLabelComponent } from './components/dual-pane/right-side/multi-input-and-label/multi-input-and-label.component';
import { OptionalAsyncPipe } from './pipes/optional-async.pipe';
import { GreenBannerComponent } from './components/green-banner/green-banner.component';
import { RadioBtnInventoryComponent } from '../inventory/components/radio-btn-inventory/radio-btn-inventory.component';
import { SingleSelectInventoryComponent } from './components/dual-pane/right-side/single-select-inventory/single-select-inventory.component';
import { MultiSelectInventoryComponent } from './components/dual-pane/right-side/multi-select-inventory/multi-select-inventory.component';
import { MultiSelectInventoryButtonComponent } from '@inventory/components/multi-select-inventory-button/multi-select-inventory-button.component';
import { PercentageDirective } from './directives/percentage.directive';
import { CustomNegativeDecimalDirective } from './directives/custom-negative-decimal.directive';
import { OrdersListDeliveredComponent } from './components/orders-list-delivered/orders-list-delivered.component';
import { TabletTouchMoveDirective } from '@shared/directives/tablet-touch-move.directive';
import { InviteExistingUserModalComponent } from './components/invite-existing-user-modal/invite-existing-user-modal.component';
import { MerchPrintModalComponent } from './components/merch-print-modal/merch-print-modal.component';

@NgModule({
  declarations: [
    FooterComponent,
    ModalWithNavComponent,
    RegexInputValidatorDirective,
    QuantityInputComponent,
    DecimalQuantityInputComponent,
    BetterBuySavingsComponent,
    CalendarComponent,
    CalendarV2ModalComponent,
    CalendarV2Component,
    CalendarV2SingleSelectComponent,
    UsfChipComponent,
    UsfCheckboxComponent,
    UsfProductTagListComponent,
    UsfTableComponent,
    BreadcrumbsComponent,
    MessageComponent,
    OrderSyncingComponent,
    ErrorDialogueComponent,
    EllipsisOptionsPopoverComponent,
    RecentPurchasePopoverComponent,
    CatchWeightPopoverComponent,
    PromoteAppComponent,
    AccessDeniedComponent,
    UsfExpandablePanelComponent,
    ProductCardImageComponent,
    ProductCardInformationComponent,
    ProductCardOrderingComponent,
    ProductCardItemNoteComponent,
    ProductCardPromotionsBannerComponent,
    ProductCardLineNumberComponent,
    ProductCardEllipsisComponent,
    ProductCardDrawerComponent,
    UsfProductCardNonSplitComponent,
    UsfProductCardSplitOriginalComponent,
    UsfProductCardSplitAlternativeComponent,
    UsfBetterBuyCardDesktopComponent,
    UsfBetterBuyCardTouchComponent,
    UsfBetterBuyDeclineModalComponent,
    UsfProductCardDesktopComponent,
    UsfProductCardTouchComponent,
    UsfProductCardInfoTouchComponent,
    UsfFilteringBannerComponent,
    UsfFilteringBannerDesktopComponent,
    UsfFilteringBannerMobileComponent,
    UsfFilteringBannerTabletComponent,
    UsfFilterTextEntryOptionsComponent,
    UsfFilterListOptionsComponent,
    UsfFilteringDatesOptionComponent,
    UsfSortOptionsComponent,
    UsfSortModalComponent,
    ProductDetailViewerComponent,
    ProductInventoryInfoComponent,
    AddToListDesktopTabletComponent,
    AddToListMobileComponent,
    AddToListInputComponent,
    AddToInventoryDesktopTabletComponent,
    AddToInventoryMobileComponent,
    AddToInventoryInputComponent,
    ReplaceProductComponent,
    ProductDetailCABCardComponent,
    InfoIconComponent,
    VideoPlayerComponent,
    SearchResultNotFoundComponent,
    HandPricingPopoverComponent,
    HandPricingCPRandNGPComponent,
    WarningPopoverWithTitleComponent,
    WarningPopoverComponent,
    WillCallModalComponent,
    WillCallConfirmModalComponent,
    PageTitleBannerComponent,
    SimpleActionModalComponent,
    DownloadOrderModalComponent,
    SimpleDownloadOrdersModalComponent,
    DownloadImportOrderModalComponent,
    SimpleSpinnerModalComponent,
    HelpModalDesktopComponent,
    HelpModalTouchComponent,
    PriceDisplayComponent,
    ListSkeletonComponent,
    OrderInfoSidebarComponent,
    SortableSectionCaretsComponent,
    SortableSectionDesktopTabletComponent,
    SortableSectionMobileComponent,
    ProductShareButtonComponent,
    InventorySectionDesktopComponent,
    InventorySectionMobileComponent,
    InventorySectionTabletComponent,
    DeleteGroupModalComponent,
    AddToOrderProductsComponent,
    AddToOrderModalDesktopTabletComponent,
    AddToOrderModalMobileComponent,
    ExistingOrderMobileComponent,
    StartNewOrderMobileComponent,
    ShowHideInputComponent,
    InlineToastComponent,
    DeleteItemsConfirmationModalComponent,
    ImportOrderModalMobileComponent,
    SelectableItemComponent,
    HighlightedSelectableItemComponent,
    RadioBtnListComponent,
    SingleSelectProductComponent,
    SingleSelectInventoryComponent,
    SelectableProductComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerWMessageModalComponent,
    PowerCustomReviewRatingStarsComponent,
    ProductDetailCardComponent,
    ProductDetailAddToOrderModalComponent,
    SharePrintDownloadComponent,
    ForceAppUpdateComponent,
    WorksWellWithContainerComponent,
    WorksWellWithModalComponent,
    ProductShippingPriceComponent,
    InviteNewUserModalComponent,
    InviteExistingUserModalComponent,
    GreenBannerComponent,
    DualPaneModalComponent,
    BasicListComponent,
    SingleSelectRadioComponent,
    SingleSelectCountableItemComponent,
    SelectableInventoryComponent,
    RadioBtnInventoryComponent,
    MultiSelectInventoryButtonComponent,
    ModalWithNavInlineComponent,
    ShowcaseDualPaneModalContainerComponent,
    PickAFileComponent,
    MultiSelectCheckboxComponent,
    SelectableCountableItemComponent,
    MassDeleteDualPaneContainerComponent,
    SearchBarMultiLineComponent,
    MerchandisingProductCarouselComponent,
    MerchPrintModalComponent,
    MultiInputAndLabelComponent,
    MultiSelectInventoryComponent,
    OrdersListDeliveredComponent,
    // Pipes
    TruncateWithEllipsisPipe,
    FormatCurrencyAndDecimalPipe,
    ZipcodePipe,
    PhoneNumberFormatPipe,
    ShowFeaturePipe,
    SentenceCasePipe,
    AcronymTitleCasePipe,
    ReplaceStringPipe,
    TimezoneDatePipe,
    TruncateAtStartEllipsisPipe,
    ShowFeaturePipe,
    ShowSplitPipe,
    FormatDateWithZeroOffsetPipe,
    OptionalAsyncPipe,
    // Directives
    AlphaNumericDirective,
    AlphaNumericAndroidDirective,
    CustomDecimalDirective,
    DecimalDirective,
    CurrencyDirective,
    MaxLengthAndroidDirective,
    ModifiedAlphaNumericDirective,
    NumberDirective,
    NumericAltDirective,
    ObserveIntersectionDirective,
    PhoneDirective,
    TestIdDirective,
    UsfFilterHostDirective,
    TrimSpaceDirective,
    ZipcodeDirective,
    CustomNegativeDecimalDirective,
    PercentageDirective,
    TabletTouchMoveDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule,
    RouterModule,
    HeaderModule,
    ShowFeatureModule,
    MatSliderModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    TranslateModule,
    MatTabsModule,
    MatTableModule,
    CdkTableModule,
    MatExpansionModule,
    ScrollingModule,
    LMADragDropModule,
    MatRadioModule,
    MatDialogModule,
    FormsModule,
    CustomScrollingModule,
    MatMomentDateModule,
  ],
  exports: [
    // modules
    IonicModule,
    TranslateModule,
    MatTabsModule,
    BetterBuySavingsComponent,
    HeaderModule,
    ShowFeatureModule,
    CustomScrollingModule,
    ScrollingModule,
    LMADragDropModule,
    MatMomentDateModule,
    // components
    FooterComponent,
    ModalWithNavComponent,
    CalendarComponent,
    CalendarV2ModalComponent,
    CalendarV2Component,
    CalendarV2SingleSelectComponent,
    QuantityInputComponent,
    DecimalQuantityInputComponent,
    UsfChipComponent,
    UsfCheckboxComponent,
    MessageComponent,
    OrderSyncingComponent,
    ErrorDialogueComponent,
    BreadcrumbsComponent,
    UsfTableComponent,
    EllipsisOptionsPopoverComponent,
    RecentPurchasePopoverComponent,
    CatchWeightPopoverComponent,
    PromoteAppComponent,
    AccessDeniedComponent,
    UsfProductTagListComponent,
    UsfExpandablePanelComponent,
    ProductCardImageComponent,
    ProductCardInformationComponent,
    ProductCardOrderingComponent,
    ProductCardItemNoteComponent,
    ProductCardPromotionsBannerComponent,
    ProductCardLineNumberComponent,
    ProductCardEllipsisComponent,
    ProductCardDrawerComponent,
    UsfProductCardNonSplitComponent,
    UsfProductCardSplitOriginalComponent,
    UsfProductCardSplitAlternativeComponent,
    UsfBetterBuyCardDesktopComponent,
    UsfBetterBuyCardTouchComponent,
    UsfBetterBuyDeclineModalComponent,
    UsfProductCardDesktopComponent,
    UsfProductCardTouchComponent,
    UsfFilteringBannerComponent,
    UsfFilteringBannerDesktopComponent,
    UsfFilteringBannerMobileComponent,
    UsfFilteringBannerTabletComponent,
    UsfFilterTextEntryOptionsComponent,
    UsfSortOptionsComponent,
    UsfSortModalComponent,
    ProductDetailViewerComponent,
    ProductInventoryInfoComponent,
    AddToListDesktopTabletComponent,
    AddToListMobileComponent,
    AddToListInputComponent,
    ReplaceProductComponent,
    ProductDetailCABCardComponent,
    SearchResultNotFoundComponent,
    HandPricingPopoverComponent,
    HandPricingCPRandNGPComponent,
    WarningPopoverWithTitleComponent,
    WarningPopoverComponent,
    WillCallModalComponent,
    WillCallConfirmModalComponent,
    PageTitleBannerComponent,
    SimpleActionModalComponent,
    DownloadOrderModalComponent,
    SimpleDownloadOrdersModalComponent,
    DownloadImportOrderModalComponent,
    HelpModalDesktopComponent,
    HelpModalTouchComponent,
    ListSkeletonComponent,
    OrderInfoSidebarComponent,
    SortableSectionCaretsComponent,
    SortableSectionDesktopTabletComponent,
    SortableSectionMobileComponent,
    ProductShareButtonComponent,
    InventorySectionDesktopComponent,
    InventorySectionTabletComponent,
    InventorySectionMobileComponent,
    DeleteGroupModalComponent,
    AddToOrderProductsComponent,
    AddToOrderModalDesktopTabletComponent,
    AddToOrderModalMobileComponent,
    ExistingOrderMobileComponent,
    StartNewOrderMobileComponent,
    ShowHideInputComponent,
    InlineToastComponent,
    DeleteItemsConfirmationModalComponent,
    ImportOrderModalMobileComponent,
    ProductDetailCardComponent,
    SelectableItemComponent,
    HighlightedSelectableItemComponent,
    RadioBtnListComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerWMessageModalComponent,
    PriceDisplayComponent,
    SharePrintDownloadComponent,
    ForceAppUpdateComponent,
    WorksWellWithContainerComponent,
    ProductShippingPriceComponent,
    InviteNewUserModalComponent,
    InviteExistingUserModalComponent,
    GreenBannerComponent,
    DualPaneModalComponent,
    SingleSelectProductComponent,
    SingleSelectInventoryComponent,
    SelectableProductComponent,
    BasicListComponent,
    SingleSelectRadioComponent,
    SingleSelectCountableItemComponent,
    SelectableInventoryComponent,
    RadioBtnInventoryComponent,
    MultiSelectInventoryButtonComponent,
    ModalWithNavInlineComponent,
    ShowcaseDualPaneModalContainerComponent,
    PickAFileComponent,
    MultiSelectCheckboxComponent,
    SelectableCountableItemComponent,
    MassDeleteDualPaneContainerComponent,
    SearchBarMultiLineComponent,
    MerchandisingProductCarouselComponent,
    MerchPrintModalComponent,
    MultiInputAndLabelComponent,
    MultiSelectInventoryComponent,
    VideoPlayerComponent,
    OrdersListDeliveredComponent,
    // Pipes
    TruncateWithEllipsisPipe,
    FormatCurrencyAndDecimalPipe,
    ZipcodePipe,
    PhoneNumberFormatPipe,
    ShowFeaturePipe,
    SentenceCasePipe,
    AcronymTitleCasePipe,
    ReplaceStringPipe,
    TranslatePipe,
    TimezoneDatePipe,
    TruncateAtStartEllipsisPipe,
    ShowSplitPipe,
    FormatDateWithZeroOffsetPipe,
    OptionalAsyncPipe,
    // Directives
    AlphaNumericDirective,
    AlphaNumericAndroidDirective,
    CustomDecimalDirective,
    DecimalDirective,
    CurrencyDirective,
    MaxLengthAndroidDirective,
    ModifiedAlphaNumericDirective,
    NumberDirective,
    NumericAltDirective,
    ObserveIntersectionDirective,
    PhoneDirective,
    RegexInputValidatorDirective,
    TestIdDirective,
    UsfFilterHostDirective,
    TrimSpaceDirective,
    ZipcodeDirective,
    CustomNegativeDecimalDirective,
    PercentageDirective,
    TabletTouchMoveDirective,
  ],
  providers: [
    IonNav,
    ServiceHandlerService,
    NavigationHelperService,
    ScreenSizeService,
    PowerReviewService,
    InviteNewUserService,
    DatePipe,
    DecimalPipe,
    SentenceCasePipe,
    AcronymTitleCasePipe,
    TimezoneDatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTraceInterceptor,
      multi: true,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}

<ion-header class="ion-no-border" [ngClass]="getHeaderClass()">
  <ion-toolbar>
    <ion-buttons *ngIf="!isMobile()" slot="end">
      <ion-button data-cy="invite-new-user-close-button" (click)="dismiss()">
        <ion-icon
          data-cy="invite-new-user-close-button-icon"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="getContentClass()">
  <ng-container *ngTemplateOutlet="currentViewTemplate"></ng-container>
</ion-content>

<ion-footer class="ion-footer-modal" [ngClass]="getFooterClass()">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="invite-new-user-cancel-button"
        [class.usf-outline-green-mobile-modal-button]="
          platformService?.platformType ===
          platformService?.platformEnum?.mobile
        "
        [class.usf-outline-green-dsktp-tblt-modal-button]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
        "
        (click)="dismiss(mobileUserInfoSelected || mobileCustomersSelected)"
      >
        {{
          mobileUserInfoSelected || mobileCustomersSelected
            ? ('i18n.common.goBack' | translate)
            : ('i18n.common.cancel' | translate)
        }}
      </ion-button>
      <ion-button
        *ngIf="(inviteUserFlag$ | async) && currentView === 'inviteNewUser'"
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.inviteNewUserForm.valid
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="inviteNewUser()"
      >
        {{ 'i18n.profilePage.inviteNewUser.button' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          (inviteUserFlag$ | async) && currentView === 'inviteUserTypeSelection'
        "
        data-cy="invite-new-user-modal-save-button"
        [ngClass]="
          this.inviteUserForm.valid
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onNext()"
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          (inviteUserFlag$ | async) &&
          modalTitle !== 'i18n.profilePage.inviteUser.inviteAUser' &&
          mobileUserInfoSelected
        "
        data-cy="invite-new-user-modal-submit-button"
        [disabled]="!selectedUser"
        [ngClass]="
          this.selectedUser
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onMobileUserInformationNext()"
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          (inviteUserFlag$ | async) &&
          modalTitle !== 'i18n.profilePage.inviteUser.inviteAUser' &&
          mobileCustomersSelected
        "
        data-cy="invite-new-user-modal-submit-button"
        [ngClass]="
          this.selectedUser && this.selectedCustomers.length > 0
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onMobileCustomersnNext()"
      >
        {{ 'i18n.common.next' | translate }}
      </ion-button>
      <ion-button
        *ngIf="
          (inviteUserFlag$ | async) &&
          modalTitle !== 'i18n.profilePage.inviteUser.inviteAUser' &&
          !mobileCustomersSelected &&
          !mobileUserInfoSelected
        "
        data-cy="invite-new-user-modal-submit-button"
        [ngClass]="
          this.selectedUser && this.selectedCustomers.length > 0
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType !==
                platformService?.platformEnum?.mobile
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-mobile-modal-button'
        "
        (click)="onSubmit()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<!-- Templates -->
<ng-template #headerTemplate>
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="(inviteUserFlag$ | async) === false"
  >
    {{ 'i18n.profilePage.inviteNewUser.title' | translate }}
    {{ 'i18n.profilePage.inviteNewUser.moxe' | translate }}
  </ion-label>
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="
      (inviteUserFlag$ | async) &&
      !mobileUserInfoSelected &&
      !mobileCustomersSelected
    "
  >
    {{ modalTitle | translate }}
  </ion-label>
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="mobileUserInfoSelected && !mobileCustomersSelected"
    (click)="resetMobileOptions()"
  >
    <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
    {{ 'i18n.profilePage.inviteUser.userInformation' | translate }}
  </ion-label>
  <ion-label
    data-cy="invite-new-user-actions-label"
    *ngIf="!mobileUserInfoSelected && mobileCustomersSelected"
    (click)="resetMobileOptions()"
  >
    <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
    {{ 'i18n.profilePage.inviteUser.customers' | translate }}
  </ion-label>
</ng-template>

<ng-template #inviteNewUserTemplate>
  <div class="description-container">
    <ion-label data-cy="invite-new-user-actions-label">
      {{ 'i18n.profilePage.inviteNewUser.modalDescription' | translate }}
    </ion-label>
    <ion-label data-cy="invite-new-user-actions-label">
      {{ 'i18n.profilePage.inviteNewUser.modalNewUsersEmail' | translate }}
    </ion-label>
  </div>
  <form
    [formGroup]="inviteNewUserForm"
    (ngSubmit)="inviteNewUser()"
    class="invite-new-user-form"
  >
    <ion-item>
      <ion-input
        aria-label="invite-new-user"
        data-cy="invite-new-user-input"
        placeholder="name@example.com"
        type="email"
        maxlength="80"
        formControlName="newUserEmailAddress"
      ></ion-input>
    </ion-item>
  </form>
  <div class="customer-container">
    <div class="customer-information" *ngFor="let info of customerInfo">
      <ion-label data-cy="invite-new-user-actions-label">
        {{ info.label | translate }}
      </ion-label>
      <ion-label data-cy="invite-new-user-actions-label">
        {{ info.value }}
      </ion-label>
    </div>
  </div>
</ng-template>

<ng-template #inviteUserTypeSelectionTemplate>
  <div class="description-container">
    <ion-label data-cy="invite-new-user-actions-label">
      {{ 'i18n.profilePage.inviteUser.typeTitle' | translate }}
    </ion-label>
  </div>
  <form [formGroup]="inviteUserForm" class="invite-user-form">
    <ion-list>
      <ion-radio-group formControlName="inviteUserType">
        <ion-item *ngFor="let userType of userTypes">
          <ion-radio
            labelPlacement="start"
            mode="md"
            [value]="userType.value"
            [attr.data-cy]="userType.cy"
          >
            {{ userType.label | translate }}
          </ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </form>
</ng-template>

<ng-template #inviteExistingUserTemplate>
  <app-invite-existing-user-modal
    #existingUserModal
    (selectExistingUser)="selectExistingUser($event)"
    (mobileUserInfoSelected)="mobileUserInfoSelected = !mobileUserInfoSelected"
    (mobileCustomersSelected)="
      mobileCustomersSelected = !mobileCustomersSelected
    "
  ></app-invite-existing-user-modal>
</ng-template>

<ng-container>
  <ion-list
    class="basic-list-container styled-list"
    [ngClass]="
      platform === platformRef.desktop
        ? 'desktop'
        : platform === platformRef.tablet
          ? 'tablet'
          : 'mobile'
    "
  >
    <div
      *ngFor="let item of vm.rightView?.inputsPane?.items; let i = index"
      [ngSwitch]="item.type"
      class="inputs"
    >
      <div *ngSwitchCase="multiInputItemsRef.input" class="single-input">
        <div class="subtitle-medium pre-line">
          {{ item?.label | translate }}
        </div>
        <div *ngIf="item?.isLoading" class="subtitle-medium pre-line">
          <ion-spinner
            name="circles"
            data-cy="loading-spinner-icon"
          ></ion-spinner>
        </div>
        <ion-item
          *ngIf="!item?.isLoading"
          [class.error-state]="!!item.hasError"
        >
          <ion-input
            [placeholder]="item.placeHolder1 | translate"
            [value]="item.inputValue"
            (ionInput)="interactWithInput($event, item, i)"
            class="alternate-style-input"
            [attr.data-cy]="dataCyTag + '-single-input-' + i"
            [maxlength]="item.maxChar ?? 999"
            [class.error-state]="!!item.hasError"
            trimSpace
          >
          </ion-input>
        </ion-item>
        <p
          *ngIf="!!item.hasError"
          class="error-message"
          [attr.data-cy]="dataCyTag + '-error-message'"
        >
          {{ item.errorMessage ?? '' | translate }}
        </p>
      </div>
      <div *ngSwitchCase="multiInputItemsRef.range" class="range-input">
        <div class="subtitle-medium pre-line">
          {{ item?.label | translate }}
        </div>
        <div *ngIf="item?.isLoading" class="subtitle-medium pre-line">
          <ion-spinner
            name="circles"
            data-cy="loading-spinner-icon"
          ></ion-spinner>
        </div>
        <div class="range" *ngIf="item?.isLoading">
          <ion-input
            [placeholder]="item.placeHolder1 | translate"
            [value]="item.rangeValueOne"
            (ionChange)="interactWithRange($event, true, item, i)"
            class="styled-input"
            inputmode="decimal"
            [maxLeftHandDigits]="item.maxDecimal ?? 6"
            [maxPrecision]="item.maxPrecision ?? 2"
            customDecimal
            [attr.data-cy]="dataCyTag + '-range-input-a-' + i"
          >
          </ion-input>
          <span> {{ 'i18n.inventory.foodCost.to' | translate }} </span>
          <ion-input
            [placeholder]="item.placeHolder2 | translate"
            [value]="item.rangeValueTwo"
            (ionChange)="interactWithRange($event, false, item, i)"
            class="styled-input"
            inputmode="decimal"
            [maxLeftHandDigits]="item.maxDecimal ?? 6"
            [maxPrecision]="item.maxPrecision ?? 2"
            customDecimal
            [attr.data-cy]="dataCyTag + '-range-input-b-' + i"
          >
          </ion-input>
        </div>
      </div>
      <div
        *ngSwitchCase="multiInputItemsRef.percentageRange"
        class="range-input"
      >
        <div class="subtitle-medium pre-line">
          {{ item?.label | translate }}
        </div>
        <div *ngIf="item?.isLoading" class="subtitle-medium pre-line">
          <ion-spinner
            name="circles"
            data-cy="loading-spinner-icon"
          ></ion-spinner>
        </div>
        <div class="range" *ngIf="!item?.isLoading">
          <ion-input
            [placeholder]="item.placeHolder1 | translate"
            [value]="
              item.isBeingInteracted || !item.rangeValueOne
                ? item.rangeValueOne
                : item.rangeValueOne + '%'
            "
            (ionChange)="interactWithRange($event, true, item, i)"
            class="styled-input"
            inputmode="numeric"
            percentageDirective
            [attr.data-cy]="dataCyTag + 'percentage-range-input-a-' + i"
            (ionBlur)="handleBlur(item)"
            (ionFocus)="handleFocus(item)"
          >
          </ion-input>
          <span> {{ 'i18n.inventory.foodCost.to' | translate }} </span>
          <ion-input
            [placeholder]="item.placeHolder2 | translate"
            [value]="
              item.isBeingInteracted || !item.rangeValueTwo
                ? item.rangeValueTwo
                : item.rangeValueTwo + '%'
            "
            (ionChange)="interactWithRange($event, false, item, i)"
            class="styled-input"
            inputmode="numeric"
            percentageDirective
            [attr.data-cy]="dataCyTag + 'percentage-range-input-b-' + i"
            (ionBlur)="handleBlur(item)"
            (ionFocus)="handleFocus(item)"
          >
          </ion-input>
        </div>
      </div>
      <div *ngSwitchCase="multiInputItemsRef.label" class="basic-label">
        <div class="subtitle-medium pre-line">
          {{ item?.label | translate }}
        </div>

        <div *ngIf="!item?.isLoading" class="subtitle-medium pre-line">
          {{ item?.subLabel | translate }}
        </div>

        <div *ngIf="item?.isLoading" class="subtitle-medium pre-line">
          <ion-spinner
            name="circles"
            data-cy="loading-spinner-icon"
          ></ion-spinner>
        </div>
      </div>
      <div *ngSwitchCase="multiInputItemsRef.iconLabel" class="icon-label">
        <ion-icon [name]="item?.subLabel"></ion-icon>

        <div class="item-subtitle-regular pre-line">
          {{ item?.label | translate }}
        </div>
      </div>
      <div *ngSwitchCase="multiInputItemsRef.blank" class="blank"></div>
      <div
        *ngSwitchCase="multiInputItemsRef.currencyInput"
        class="currency-input"
      >
        <div class="subtitle-medium pre-line">
          {{ item?.label | translate }}
        </div>
        <div *ngIf="item?.isLoading" class="subtitle-medium pre-line">
          <ion-spinner
            name="circles"
            data-cy="loading-spinner-icon"
          ></ion-spinner>
        </div>
        <ion-input
          *ngIf="!item?.isLoading"
          id="currency-item.label-{{ i }}"
          inputmode="decimal"
          [placeholder]="item.placeHolder1 | translate"
          [value]="
            item.isBeingInteracted
              ? item.inputValue
              : (item.inputValue | currency)
          "
          (ionBlur)="handleBlur(item)"
          (ionFocus)="handleFocus(item)"
          (ionChange)="interactWithInput($event, item, i)"
          customDecimal
          [maxLeftHandDigits]="item.maxDecimal ?? 6"
          [maxPrecision]="item.maxPrecision ?? 2"
          class="styled-input"
          [attr.data-cy]="dataCyTag + '-currency-input-' + i"
        >
        </ion-input>
      </div>
      <div
        *ngSwitchCase="multiInputItemsRef.negativeCurrency"
        class="currency-input"
      >
        <div class="subtitle-medium pre-line">
          {{ item?.label | translate }}
        </div>
        <div *ngIf="item?.isLoading" class="subtitle-medium pre-line">
          <ion-spinner
            name="circles"
            data-cy="loading-spinner-icon"
          ></ion-spinner>
        </div>
        <ion-input
          *ngIf="!item?.isLoading"
          id="negative-currency-item.label-{{ i }}"
          [placeholder]="item.placeHolder1 | translate"
          [value]="
            item.isBeingInteracted
              ? item.inputValue
              : (item.inputValue | currency)
          "
          (ionBlur)="handleBlur(item)"
          (ionFocus)="handleFocus(item)"
          (ionChange)="interactWithInput($event, item, i)"
          customNegativeDecimal
          [maxLeftHandDigits]="item.maxDecimal ?? 6"
          [maxPrecision]="item.maxPrecision ?? 2"
          class="styled-input"
          [attr.data-cy]="dataCyTag + '-negative-currency-input-' + i"
        >
        </ion-input>
      </div>
      <div *ngSwitchCase="multiInputItemsRef.rangeLabel" class="range-label">
        <div class="subtitle-medium pre-line">
          {{ item?.label | translate }}
        </div>
        <div *ngIf="item?.isLoading" class="subtitle-medium pre-line">
          <ion-spinner
            name="circles"
            data-cy="loading-spinner-icon"
          ></ion-spinner>
        </div>
        <div
          *ngIf="!!item.rangeLabelIsArray && !item?.isLoading"
          class="range-container"
        >
          <div>
            <p
              *ngFor="let value of item?.rangeValueOne ?? []"
              class="subtitle-medium"
            >
              <span *ngIf="value !== (item?.rangeValueOne ?? [])[0]">,</span>
              {{ value }}
            </p>
          </div>
          <p class="subtitle-medium">-</p>
          <div>
            <p
              *ngFor="let value of item?.rangeValueTwo ?? []"
              class="subtitle-medium"
            >
              <span *ngIf="value !== (item?.rangeValueTwo ?? [])[0]">,</span>
              {{ value }}
            </p>
          </div>
        </div>
        <div
          *ngIf="!item.rangeLabelIsArray && !item?.isLoading"
          class="range-container"
        >
          <p class="subtitle-medium">
            {{ item.rangeValueOne }}
          </p>
          <p class="subtitle-medium">-</p>
          <p class="subtitle-medium">{{ item.rangeValueTwo }}</p>
        </div>
      </div>
      <ion-item-divider
        class="divider"
        *ngIf="!!item?.underline"
      ></ion-item-divider>
    </div>
  </ion-list>
</ng-container>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@app/user/services';
import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import { CustomizationOptions } from '@shared/constants/customizations.enum';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PATHS } from '@shared/constants/paths';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit, OnDestroy {
  @Input() isNavRestricted: boolean;
  @Input() isManageCustomizationsLogo: boolean;
  @Input() currentUrl: string;
  customizationsData$: Subscription = new Subscription();
  public headerLogo: string;
  public headerLogoUrl: string;
  public footerLogo: string;
  public footerLogoUrl: string;
  private usfoodsAem =
    'https://www.usfoods.com/content/dam/usf/UX/library/icons/logos-and-social-media/usfoods-color.svg';
  @Input() footer = false;

  constructor(
    private userService: UserService,
    readonly navigationHelperService: NavigationHelperService,
  ) {}

  ngOnInit(): void {
    this.setLogos();
  }

  routeToHome() {
    if (this.isManageCustomizationsLogo) {
      if (this.footer) {
        if (this.footerLogoUrl.indexOf('premier') > -1) {
          window.open(this.footerLogoUrl, '_blank');
        } else {
          this.navigationHelperService.routeTo(this.footerLogoUrl, {});
        }
      } else {
        this.navigationHelperService.routeTo(this.headerLogoUrl, {});
      }
    } else {
      this.navigationHelperService.routeTo(PATHS.HOME);
    }
  }

  setLogos() {
    this.customizationsData$ = this.userService
      .userCustomizations$()
      .pipe(filter(customizations => !!customizations))
      .subscribe(customizations => {
        customizations?.properties?.forEach(customization => {
          if (customization.name === CustomizationOptions.logo) {
            this.headerLogo = customization.value;
          }
          if (customization.name === CustomizationOptions.footer) {
            this.footerLogo = customization.value;
          }
          if (customization.name === CustomizationOptions.logoUrl) {
            this.headerLogoUrl = customization.value;
          }
          if (customization.name === CustomizationOptions.footerUrl) {
            this.footerLogoUrl = customization.value;
          }
        });
      });
  }

  getClass() {
    if (this.footer && this.footerLogo != this.usfoodsAem) {
      return 'national-customers-footer-logo';
    } else if (!this.footer && this.headerLogo != this.usfoodsAem) {
      return 'national-customers-logo';
    } else {
      return 'usf-logo';
    }
  }

  getImgSrc() {
    if (this.isManageCustomizationsLogo) {
      return this.footer ? this.footerLogo : this.headerLogo;
    } else {
      if (this.isNavRestricted) {
        return 'assets/images/usfoods-logo-gray.svg';
      } else {
        return 'assets/images/usfoods-logo-color.svg';
      }
    }
  }

  getDataCy() {
    if (!this.isManageCustomizationsLogo) {
      if (this.footer) {
        if (this.currentUrl === '/desktop/order/order-exceptions') {
          return 'footer-usf-logo-gray-image';
        } else {
          return 'footer-usf-logo-color-image';
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.customizationsData$?.unsubscribe();
  }
}

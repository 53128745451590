import { Component, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ZipCodeChangeBaseComponent } from '../zip-code-change-base.component';
import { LoginService } from '@app/login/services';

@Component({
  selector: 'app-zip-code-change-touch',
  templateUrl: './zip-code-change-touch.component.html',
  styleUrls: ['./zip-code-change-touch.component.scss'],
})
export class ZipCodeChangeTouchComponent extends ZipCodeChangeBaseComponent {
  @Input() zipCode : number;

  constructor(
    private modalController: ModalController,
    platformService: PlatformService,
    loginService: LoginService,
  ) {
    super(platformService,loginService);
  }


  async switchGuestZip(event: any) {
    this.modalController.dismiss();
    await this.loginService.switchGuestZip(Number(this.zipCode));
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }
}

import {
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  PanAppState,
  PlatformService,
  SelectedCustomerState,
} from '@panamax/app-state';
import { InviteNewUserService } from '@shared/services/invite-new-user/invite-new-user.service';
import { User } from '@usf/user-types/user';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InviteExistingUserModalComponent } from '../invite-existing-user-modal/invite-existing-user-modal.component';

@Component({
  selector: 'app-invite-new-user-modal',
  templateUrl: './invite-new-user-modal.component.html',
  styleUrls: ['./invite-new-user-modal.component.scss'],
})
export class InviteNewUserModalComponent implements OnInit, OnDestroy {
  @ViewChild('inviteNewUserTemplate', { static: true })
  inviteNewUserTemplate: TemplateRef<any>;
  @ViewChild('inviteUserTypeSelectionTemplate', { static: true })
  inviteUserTypeSelectionTemplate: TemplateRef<any>;
  @ViewChild('inviteExistingUserTemplate', { static: true })
  inviteExistingUserTemplate: TemplateRef<any>;
  @ViewChild('existingUserModal')
  inviteExistingUserModal!: InviteExistingUserModalComponent;

  inviteNewUserForm: FormGroup;
  inviteUserForm: FormGroup;

  inviteUserFlag$: Observable<boolean>;
  customer: SelectedCustomerState;
  selectedUser: User;
  selectedCustomers: string[];

  modalTitle = 'i18n.profilePage.inviteUser.inviteAUser';

  mobileUserInfoSelected = false;
  mobileCustomersSelected = false;

  public destroy$ = new Subject<void>();

  customerInfo: Array<{ label: string; value: string | number }>;
  currentView:
    | 'inviteNewUser'
    | 'inviteUserTypeSelection'
    | 'inviteExistingUser' = 'inviteUserTypeSelection';
  userTypes = [
    {
      value: 'newUser',
      cy: 'radio-newUser',
      label: 'i18n.profilePage.inviteUser.newUser',
    },
    {
      value: 'existingUser',
      cy: 'radio-existingUser',
      label: 'i18n.profilePage.inviteUser.existingUser',
    },
  ];

  constructor(
    public platformService: PlatformService,
    public inviteNewUserService: InviteNewUserService,
    private modalController: ModalController,
    private fb: FormBuilder,
    private panAppState: PanAppState,
  ) {}

  ngOnInit(): void {
    this.inviteUserFlag$ = this.inviteNewUserService.inviteUserFlag$();
    this.inviteNewUserService.setupInviteUserSubscriptions();
    this.initForms();

    this.panAppState.customer$
      .pipe(
        takeUntil(this.destroy$),
        filter(cust => !!cust),
      )
      .subscribe(customer => {
        if (customer) {
          this.customer = customer;
          this.updateCustomerInfo(customer);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForms() {
    this.inviteNewUserForm = this.fb.group({
      newUserEmailAddress: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(80)],
      ],
    });
    this.inviteUserForm = this.fb.group({
      inviteUserType: ['', Validators.required],
    });
  }

  public updateCustomerInfo(customer: SelectedCustomerState) {
    this.customerInfo = [
      {
        label: 'i18n.profilePage.inviteNewUser.modalCustomerName',
        value: customer.customerName,
      },
      {
        label: 'i18n.profilePage.inviteNewUser.modalCustomerNumber',
        value: customer.customerNumber,
      },
      {
        label: 'i18n.profilePage.inviteNewUser.modalDivisionNumber',
        value: customer.divisionNumber,
      },
    ];
  }

  selectExistingUser({ user, customers }: { user: User; customers: string[] }) {
    this.selectedUser = user;
    this.selectedCustomers = customers;
  }

  resetMobileOptions() {
    this.mobileUserInfoSelected = false;
    this.mobileCustomersSelected = false;
    this.inviteExistingUserModal?.clearSelectedOption();
  }

  async dismiss(isMobileSection?: boolean) {
    if (isMobileSection) {
      this.resetMobileOptions();
    } else {
      this.inviteNewUserForm.reset();
      await this.modalController.dismiss(true);
    }
  }

  inviteNewUser() {
    if (this.inviteNewUserForm?.valid && this.customer) {
      this.inviteNewUserService.inviteNewUser(
        this.inviteNewUserForm.get('newUserEmailAddress')?.value,
        [
          {
            customerNumber: this.customer?.customerNumber,
            divisionNumber: this.customer?.divisionNumber,
          },
        ],
      );
      this.dismiss();
    }
  }

  onMobileUserInformationNext() {
    this.mobileUserInfoSelected = false;
    this.inviteExistingUserModal.mobileUserInfoSelectedNext();
    this.mobileCustomersSelected = true;
  }

  onMobileCustomersnNext() {
    this.mobileCustomersSelected = false;
    this.inviteExistingUserModal.clearSelectedOption();
  }

  async onNext() {
    const selectedUserType = this.inviteUserForm.get('inviteUserType')?.value;
    if (selectedUserType === 'existingUser') {
      this.modalTitle = 'i18n.profilePage.inviteUser.inviteAnExistingUser';
      this.currentView = 'inviteExistingUser';
      await this.modalController.getTop()?.then(modal => {
        if (modal && this.platformService.platformType === 'desktop') {
          modal.cssClass = 'invite-existing-user-modal';
        }
      });
    } else {
      this.currentView = 'inviteNewUser';
      this.modalTitle = 'i18n.profilePage.inviteUser.inviteAUser';
      await this.modalController.getTop()?.then(modal => {
        if (modal && this.platformService.platformType === 'desktop') {
          modal.cssClass = 'invite-new-user-modal';
        }
      });
    }
  }

  onSubmit() {
    console.log(
      `Invite Existing User: ${this.selectedUser?.userName} ${
        this.selectedCustomers?.length
          ? ' with new customers: ' + this.selectedCustomers?.join(', ')
          : ' with no new customers'
      }`,
    );
  }

  isMobile(): boolean {
    return (
      this.platformService.platformType ===
      this.platformService.platformEnum.mobile
    );
  }

  getHeaderClass(): string {
    return `ion-header-modal-${this.platformService.platformType}`;
  }

  getContentClass(): string {
    return `ion-content-modal-${this.platformService.platformType}`;
  }

  getFooterClass(): string {
    return `ion-footer-modal-${this.platformService.platformType}`;
  }

  get currentViewTemplate() {
    switch (this.currentView) {
      case 'inviteNewUser':
        return this.inviteNewUserTemplate;
      case 'inviteUserTypeSelection':
        return this.inviteUserTypeSelectionTemplate;
      case 'inviteExistingUser':
        return this.inviteExistingUserTemplate;
      default:
        return this.inviteUserTypeSelectionTemplate;
    }
  }

  isInviteNewUserView(): boolean {
    return this.currentView === 'inviteNewUser';
  }

  isInviteUserTypeSelectionView(): boolean {
    return this.currentView === 'inviteUserTypeSelection';
  }
}

<ion-content
  class="invite-existing-user-content"
  [ngClass]="{
    'ion-content-modal-tablet':
      platform.platformType === platform?.platformEnum?.tablet,
    'ion-content-modal-desktop':
      platform.platformType === platform?.platformEnum?.desktop,
  }"
>
  <ion-grid>
    <ion-row>
      <!-- Options Column -->
      <ion-col
        size-xl="5.5"
        size-lg="5.5"
        size-md="5.5"
        size-sm="12"
        size-xs="12"
        [ngClass]="{
          hidden:
            platform.platformType === platform?.platformEnum?.mobile &&
            selectedOption !== '',
        }"
        class="invite-existing-user-options"
      >
        <ion-item
          *ngFor="let section of SECTIONS"
          [attr.data-cy]="'invite-existing-user-' + section + '-option'"
          [class.selected]="selectedOption === section"
          detail
          (click)="setSelectedOption(section)"
        >
          <ion-label>
            {{ 'i18n.profilePage.inviteUser.' + section | translate }}
            <span
              class="selected-user"
              *ngIf="section === 'userInformation' && isUserSelected"
              [attr.data-cy]="'invite-existing-user-' + section + '-selected'"
            >
              {{ result?.user?.userName | uppercase }}
            </span>
            <span
              class="selected-user"
              *ngIf="
                section === 'customers' && this.result.customers.length > 0
              "
              [attr.data-cy]="'invite-existing-user-' + section + '-selected'"
            >
              {{
                this.result.customers.length === 1
                  ? getSingleCustomerSelectedName()
                  : getSelectedCustomersCount()
              }}
            </span>
          </ion-label>
        </ion-item>
      </ion-col>

      <!-- User Information Column -->
      <ion-col
        size-xl="5.5"
        size-lg="5.5"
        size-md="5.5"
        size-sm="12"
        size-xs="12"
        offset-xl="1"
        offset-lg="1"
        offset-md="1"
        offset-sm="0"
        offset-xs="0"
        [ngClass]="{
          hidden:
            platform.platformType === platform?.platformEnum?.mobile &&
            selectedOption === '',
        }"
        class="invite-existing-user-input"
      >
        <!-- Users -->
        <div
          *ngIf="selectedOption === 'userInformation'"
          class="user-info-container"
        >
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteUser.selectUser' | translate
          }}</ion-label>
          <ion-searchbar
            aria-label="user-searchbar"
            data-cy="user-searchbar"
            class="search"
            mode="md"
            maxLength="30"
            inputmode="search"
            enterkeyhint="search"
            placeholder="{{
              'i18n.profilePage.inviteUser.searchByUsername' | translate
            }}"
            (ionBlur)="searchUser($event)"
            (ionClear)="clearUser()"
            (ionChange)="searchUser($event)"
            (ionInput)="handleInput($event)"
          ></ion-searchbar>
          <ion-list class="user-list">
            <ion-radio-group
              [value]="isUserSelected ? result?.user?.userName : null"
              (ionChange)="selectUser()"
            >
              <ion-item
                class="user-item"
                *ngIf="result?.user && result?.user?.userName !== ''"
                (click)="selectUser()"
              >
                <ion-label id="user-details">
                  <span class="user-details-username">{{
                    result?.user?.userName | uppercase
                  }}</span>
                  <span *ngIf="result?.user?.email">
                    {{ result?.user?.email }}
                  </span>
                  <span class="no-email-found" *ngIf="!result?.user?.email">
                    {{ 'i18n.profilePage.inviteUser.noEmail' | translate }}
                  </span>
                  <span *ngIf="result?.user?.email">
                    {{ result?.user?.firstName }}&nbsp;{{
                      result?.user?.lastName
                    }}
                  </span>
                </ion-label>
                <ion-radio
                  labelPlacement="start"
                  mode="md"
                  aria-label="user-option"
                  [attr.data-cy]="
                    'inviteUser-user-' + result?.user?.userName + '-option'
                  "
                  [value]="result?.user?.userName"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>

          <div *ngIf="!result?.user" class="no-user-found">
            {{ 'i18n.profilePage.inviteUser.noUser' | translate }}
          </div>
        </div>
        <!-- Customers -->
        <div *ngIf="selectedOption === 'customers'" class="user-info-container">
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteUser.selectCustomers' | translate
          }}</ion-label>
          <ion-searchbar
            aria-label="customer-searchbar"
            data-cy="customers-searchbar"
            class="search"
            mode="md"
            inputmode="search"
            enterkeyhint="search"
            placeholder="{{ 'i18n.common.searchCustomers' | translate }}"
            (ionBlur)="searchCustomer($event)"
            (ionClear)="resetSearch()"
            (ionInput)="searchCustomer($event)"
            (ionChange)="searchCustomer($event)"
          ></ion-searchbar>
          <span *ngIf="customersMaxLimitReached" class="max-customers-reached">
            {{ 'i18n.profilePage.inviteUser.maxCustomers' | translate }}
          </span>
          <div *ngIf="searchedCustomers.length === 0" class="no-user-found">
            {{ 'i18n.profilePage.inviteUser.noCustomer' | translate }}
          </div>
          <ion-list
            class="customers-list"
            *ngIf="searchedCustomers.length > 0"
            [disabled]="this.result.user"
          >
            <ion-item-divider></ion-item-divider>
            <ion-item
              *ngFor="let customer of searchedCustomers"
              [disabled]="
                customer?.disabled ||
                (customersMaxLimitReached && !customer?.selected)
              "
              (click)="toggleCustomerSelection(customer, $event)"
            >
              <div id="customer-name">
                <span>
                  {{ customer.customerName | titlecase }}&nbsp;#{{
                    customer.customerNumber
                  }}</span
                >
              </div>
              <ion-checkbox
                mode="ios"
                slot="end"
                [attr.data-cy]="
                  'invite-existing-user-customer-' +
                  customer.customerNumber +
                  '-option'
                "
                [checked]="customer?.selected"
                labelPlacement="start"
                [aria-label]="
                  customer.customerName + ' #' + customer.customerNumber
                "
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
